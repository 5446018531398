import {ListenerNotify, Notification, Notifications, NotificationType} from "../types/notification.interface";
import {AxiosError} from "axios";

class NotificationService {
    private listeners: ListenerNotify[] = [];
    private notifications: Notification[] = [];
    private nextId: number = 1;

    addListener(listener: ListenerNotify): void {
        this.listeners.push(listener);
    }

    removeListener(listener: ListenerNotify): void {
        this.listeners = this.listeners.filter((l: ListenerNotify): boolean => l !== listener);
    }

    notify(title: string, message: string, type: NotificationType, duration: number = 3000): void {
        const notification: Notification = {
            id: this.nextId++,
            title,
            message,
            type,
            duration
        };
        this.notifications.push(notification);
        this.listeners.forEach((listener: ListenerNotify) => listener(this.notifications));

        setTimeout(() => {
            this.remove(notification);
        }, duration);
    }

    remove(notification: Notification): void {
        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
        this.listeners.forEach((listener) => listener(this.notifications));
    }

    success(message: string, title: string = "Sukces", duration?: number): void {
        this.notify(title, message, Notifications.SUCCESS, duration);
    }

    error(customMessage: string, err?: unknown, title: string = "Błąd", duration?: number): void {
        let errorMessage: string = customMessage;

        if (err instanceof AxiosError) {
            const hasErrorMessage: boolean = typeof err.response?.data === "string" && err.response.data !== "";
            errorMessage = hasErrorMessage ? err?.response?.data as string : customMessage
        } else if (err instanceof Error) {
            errorMessage = err.message || customMessage;
        } else if (typeof err === "string") {
            errorMessage = err;
        }

        this.notify(title, errorMessage, Notifications.ERROR, duration);
    }

    warning(message: string, title: string = "Uwaga", duration?: number): void {
        this.notify(title, message, Notifications.WARNING, duration);
    }

    info(message: string, title: string = "Informacja", duration?: number): void {
        this.notify(title, message, Notifications.INFO, duration);
    }
}

export default new NotificationService();
