import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {CalendarEvent} from "../types/calendar-event.interface";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/class-names";
import CalendarService from "../services/calendar.service";
import {useCalendarForm} from "../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../types/type-event.interface";

interface ExtraEventsPopoverProps {
    date: Date;
    events: CalendarEvent[];
    onClose: () => void;
    parentRef: React.RefObject<HTMLDivElement>;
    calendarRef: React.RefObject<HTMLDivElement>;
}

const ExtraEventsPopover: React.FC<ExtraEventsPopoverProps> = ({date, events, onClose, parentRef, calendarRef}) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const [positionClass, setPositionClass] = useState<string>("");
    const {openForm} = useCalendarForm();

    useLayoutEffect(() => {
        const positionPopover = () => {
            const popover = popoverRef.current;
            const parent = parentRef.current;
            const calendar = calendarRef.current;

            if (popover && parent && calendar) {
                const parentRect = parent.getBoundingClientRect();
                const calendarRect = calendar.getBoundingClientRect();

                const popoverWidth = popover.offsetWidth;
                const popoverHeight = popover.offsetHeight;

                const spaceRight = calendarRect.right - parentRect.right;
                const spaceLeft = parentRect.left - calendarRect.left;
                const spaceBelow = calendarRect.bottom - parentRect.bottom;
                const spaceAbove = parentRect.top - calendarRect.top;

                let newPositionClass = "";

                if (spaceRight >= popoverWidth && spaceBelow >= popoverHeight) {
                    newPositionClass = "top-left";
                } else if (spaceRight >= popoverWidth && spaceAbove >= popoverHeight) {
                    newPositionClass = "bottom-left";
                } else if (spaceLeft >= popoverWidth && spaceBelow >= popoverHeight) {
                    newPositionClass = "top-right";
                } else if (spaceLeft >= popoverWidth && spaceAbove >= popoverHeight) {
                    newPositionClass = "bottom-right";
                }

                if (window.innerWidth < 640) {
                    newPositionClass = "mobile";
                }

                setPositionClass(newPositionClass);
            }
        };

        positionPopover();

        window.addEventListener('resize', positionPopover);
        window.addEventListener('scroll', positionPopover);

        return () => {
            window.removeEventListener('resize', positionPopover);
            window.removeEventListener('scroll', positionPopover);
        };
    }, [parentRef, calendarRef]);

    const handleOpenForm = (event: CalendarEvent) => {
        openForm(TypeCalendarEvent.EVENT, {
            calendarTypeId: event.calendarTypeName === 'Zadanie' ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT,
            endDate: event.endDate,
            startDate: event.startDate,
            title: event.title,
            isFullDay: event.isFullDay,
            id: event.id,
            calendarTypeName: event.calendarTypeName,
        });
        onClose();
    };

    return (
        <div
            ref={popoverRef}
            className={classNames(
                "absolute text-gray-900 z-50 bg-white border border-gray-300 rounded-lg shadow-lg p-4 max-w-xs",
                positionClass === "top-left" && "top-0 left-0 mt-2 ml-2",
                positionClass === "top-right" && "top-0 right-0 mt-2 mr-2",
                positionClass === "bottom-left" && "bottom-0 left-0 mb-2 ml-2",
                positionClass === "bottom-right" && "bottom-0 right-0 mb-2 mr-2",
                positionClass === "mobile" && "left-0 top-0 mt-12",
                'w-svw sm:w-auto '
            )}
        >
            <div className="flex justify-between items-center relative">
                <div className='flex flex-col items-center w-full'>
                    <span className="text-base leading-6 font-normal">{CalendarService.getWeekdayByDate(date)}</span>
                    <span className="text-lg leading-7 font-semibold">{date.getDate()}</span>
                </div>
                <button onClick={onClose} className="absolute top-0 right-0 text-gray-600 hover:text-gray-900">
                    <XMarkIcon className='w-5 h-5'/>
                </button>
            </div>
            <div className="flex flex-col">
                {events.map((event, index) => (
                    <div
                        onClick={() => handleOpenForm(event)}
                        key={index}
                        className={classNames(
                            'flex items-center border border-gray-300 text-xs rounded-md px-2 py-1 mt-1 ' +
                            'overflow-hidden text-ellipsis whitespace-nowrap max-w-full min-w-full shadow-base cursor-pointer',
                            event.completed && 'border-primary_600'
                        )}
                        title={event.title}
                    >
                        {event.completed && (
                            <CheckCircleIcon className={classNames(
                                'w-4 h-4 mr-1 text-green-600'
                            )}/>
                        )}
                        <span className='truncate max-w-full flex gap-2'>
                            <span className="text-gray-900 whitespace-nowrap text-xs leading-4 font-normal">
                                {event.displayTime}
                            </span>
                            <span
                                className="text-gray-900 truncate whitespace-nowrap max-w-full text-xs leading-4 font-bold">
                                {event.displayTitle}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExtraEventsPopover;
