import {TypeParent} from "../../view-builder/types/type-parent.interface";
import DynamicView from "../../view-builder";
import ChapterCompletedPrompt from "./chapter-completed-prompt";
import {useLocation} from "react-router-dom";

const Chapter = () => {
    const location = useLocation();
    const isCompletedChapter: boolean = location.state.isCompleted;

    return (
        <div className='h-full'>
            <DynamicView
                isCompleted={isCompletedChapter}
                errorFetching='Nie udało się załadować rozdziału.'
                titleParamName='chapter'
                typeView={TypeParent.CHAPTER}/>
            <ChapterCompletedPrompt isCompleted={isCompletedChapter}/>
        </div>
    )
}

export default Chapter
