import {CalendarEvent} from "../types/calendar-event.interface";
import {Task} from "../types/task.interface";
import CalendarService from "./calendar.service";
import {Week} from "../types/week.interface";
import {Day} from "../types/day.interface";
import {TypeCalendarEvent} from "../types/type-event.interface";

class ScheduleService {
    getMonthStartAndEndDates(date: Date): [Date, Date] {
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return [start, end];
    }

    generateEmptySchedule(startDate: Date): Week[] {
        const year = startDate.getFullYear();
        const month = startDate.getMonth();

        const daysInMonth = CalendarService.getDaysInMonth(year, month);
        const schedule: Week[] = [];
        let currentWeek: Week = {label: '', days: []};

        for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(year, month, i);
            const dayOfWeek = date.getDay();

            const day: Day = {
                weekday: CalendarService.getWeekdayByDay((dayOfWeek === 0) ? 6 : dayOfWeek - 1),
                date: i,
                tasks: [],
            };

            currentWeek.days.push(day);

            if (dayOfWeek === 0 || i === daysInMonth) {
                const firstDayLabel = currentWeek.days[0]?.date;

                if (firstDayLabel !== undefined && firstDayLabel === i) {
                    currentWeek.label = `${firstDayLabel} ${CalendarService.getMonthName(month)}`;
                } else if (firstDayLabel !== undefined) {
                    currentWeek.label = `${firstDayLabel}-${i} ${CalendarService.getMonthName(month)}`;
                }

                schedule.push(currentWeek);
                currentWeek = {label: '', days: []};
            }
        }

        return schedule;
    }


    sortTasksByTime(currentDate: Date) {
        return (taskA: Task, taskB: Task): number => {
            const [startTimeA, endTimeA] = taskA.time.split(' - ').map(time => time.split(':').map(Number));
            const [startTimeB, endTimeB] = taskB.time.split(' - ').map(time => time.split(':').map(Number));
            const dateAStart = new Date(currentDate);
            const dateAEnd = new Date(currentDate);
            const dateBStart = new Date(currentDate);
            const dateBEnd = new Date(currentDate);

            dateAStart.setHours(startTimeA[0], startTimeA[1]);
            dateAEnd.setHours(endTimeA[0], endTimeA[1]);
            dateBStart.setHours(startTimeB[0], startTimeB[1]);
            dateBEnd.setHours(endTimeB[0], endTimeB[1]);

            if (dateAStart.getTime() !== dateBStart.getTime()) {
                return dateAStart.getTime() - dateBStart.getTime();
            }
            return dateAEnd.getTime() - dateBEnd.getTime();
        };
    }

    getTasksForDay(day: number, apiData: CalendarEvent[], currentDate: Date): Task[] {
        return apiData
            .filter(event => this.isEventInDayRange(event.startDate, event.endDate, day, currentDate))
            .map(event => {
                const start = new Date(event.startDate).setHours(0, 0, 0, 0);
                const end = new Date(event.endDate).setHours(23, 59, 59, 999);
                const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getTime();
                const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                const currentDayIndex = Math.ceil((currentDay - start) / (1000 * 60 * 60 * 24)) + 1;

                const titleWithDayIndex = totalDays > 1
                    ? `(${currentDayIndex} z ${totalDays}) ${event.title}`
                    : event.title;

                const isFullyDay = event.startDate.includes('T00:00:00') && event.endDate.includes('T00:00:00');

                return {
                    title: event.title,
                    displayTitle: titleWithDayIndex,
                    time: this.formatEventTime(event.startDate, event.endDate),
                    isFullDay: isFullyDay,
                    completed: event.completed,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    id: event.id,
                    calendarTypeName: event.calendarTypeName,
                    calendarTypeId: event.calendarTypeName === "Zadanie" ? TypeCalendarEvent.TASK : TypeCalendarEvent.EVENT,
                } as Task;
            });
    }


    private isEventInDayRange(startDate: string, endDate: string, day: number, currentDate: Date): boolean {
        const start = new Date(startDate).setHours(0, 0, 0, 0);
        const end = new Date(endDate).setHours(23, 59, 59, 999);
        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getTime();

        return currentDay >= start && currentDay <= end;
    }

    private formatEventTime(startDateString: string, endDateString: string): string {
        const startDate: Date = new Date(startDateString);
        const endDate: Date = new Date(endDateString);
        const startTime: string = `${startDate.getHours()}:${String(startDate.getMinutes()).padStart(2, '0')}`;
        const endTime: string = `${endDate.getHours()}:${String(endDate.getMinutes()).padStart(2, '0')}`
        return `${startTime} - ${endTime}`;
    }
}

export default new ScheduleService();
