import React, {useEffect, useRef} from 'react';
import Completed from "./completed";

interface ProgressRingProps {
    radius: number;
    stroke: number;
    progress: number;
    isCompleted: boolean;
}

const ProgressRing: React.FC<ProgressRingProps> = ({radius, stroke, progress, isCompleted}) => {
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;
    const circleRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
        const circle = circleRef.current;
        if (circle) {
            circle.style.transition = 'stroke-dashoffset 1s ease-in-out';
            circle.style.strokeDashoffset = String(strokeDashoffset);
        }
    }, [strokeDashoffset]);

    return (
        <div className='w-fit h-fit relative'>
            <svg
                className="progress-ring w-fit h-fit"
                height={radius * 2}
                width={radius * 2}
            >
                <g style={{transform: 'rotate(-90deg)', transformOrigin: 'center'}}>
                    <circle
                        className="text-gray-300"
                        strokeWidth={stroke}
                        stroke="currentColor"
                        fill="transparent"
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                    <circle
                        ref={circleRef}
                        className="text-green-600"
                        strokeWidth={stroke}
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={circumference.toString()}
                        strokeLinecap="round"
                        stroke="currentColor"
                        fill="transparent"
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                </g>
                <text x={radius} y={radius + 5} textAnchor="middle" className="text-gray-700 text-sm">
                    {progress}
                </text>
            </svg>
            <Completed isCompleted={isCompleted} bgColor='bg-primary_500' className='absolute top-[6px] right-[6px] '/>
        </div>
    );
};

export default ProgressRing;
