import {CheckCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {Task} from "../types/task.interface";
import {useCalendarForm} from "../hooks/use-calendar-form";
import {TypeCalendarEvent} from "../types/type-event.interface";
import CompleteTaskButton from "./complete-task-button";
import {XMarkIcon} from "@heroicons/react/20/solid";

interface ScheduleTaskItemProps {
    task: Task,
    index: number,
    nextTaskStartDateString: string | null,
}

const ScheduleTaskItem = (props: ScheduleTaskItemProps) => {
    const {task, index} = props
    const [refreshKey, setRefreshKey] = useState(0)
    const {openForm} = useCalendarForm();

    useEffect(() => {
    }, [refreshKey]);

    return (
        <div key={index} className='cursor-pointer' onClick={() => openForm(TypeCalendarEvent.EVENT, task)}>
            <div
                className={`relative flex flex-col gap-1 p-3 mb-2 rounded-lg bg-gray-50 ${task.completed ? 'border-primary_600' : 'border-gray-300'} border`}
            >
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center flex-grow">
                        <span className="text-base leading-6 font-bold text-gray-900">{task.displayTitle}</span>
                        {task.completed && <CheckCircleIcon className='w-5 h-5 text-primary_600'/>}
                    </div>
                </div>
                <div className="text-sm leading-5 font-normal text-gray-900">{!task.isFullDay ? task.time : ''}</div>
                {task.calendarTypeId === TypeCalendarEvent.TASK &&
                    <CompleteTaskButton task={task} onSuccess={() => setRefreshKey(prevState => prevState + 1)}/>}
            </div>
        </div>
    );
}

export default ScheduleTaskItem
