import React, { useState } from 'react';
import { BaseBlock } from "../types/base-block.interface";
import { IAnimationBlock } from "../types/blocks/animation-block.interface";

const AnimationBlock: React.FC<BaseBlock<IAnimationBlock>> = (props: BaseBlock<IAnimationBlock>) => {
    const [isBurning, setIsBurning] = useState(false);
    const fireCount = 25;
    const smokeCount = 15;
    const sparksCount = 10;
    const ashesCount = 20;

    const handleBurn = () => {
        setIsBurning(true);
    };

    const generateElements = (className: string, count: number) => {
        return Array.from({ length: count }).map((_, index) => (
            <div
                key={index}
                className={className}
                style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    animationDelay: `${Math.random() * 2}s`,
                    transform: `scale(${Math.random() * 1.5 + 0.5}) rotate(${Math.random() * 360}deg)`,
                }}
            ></div>
        ));
    };

    return (
        <div className="flex flex-col items-center">
            <div className="burn-container">
                <div className={`paper-container ${isBurning ? 'burn' : ''}`}>
                    <div className="paper-content">
                        Tutaj jest tymczasowa treść, która zostanie spalona.
                    </div>
                    {isBurning && (
                        <>
                            {generateElements('fire', fireCount)}
                            {generateElements('smoke', smokeCount)}
                            {generateElements('sparks', sparksCount)}
                            {generateElements('ashes', ashesCount)}
                        </>
                    )}
                </div>
            </div>
            {!isBurning && (
                <button className="burn-button" onClick={handleBurn}>
                    Spal treść
                </button>
            )}
        </div>
    );
};

export default AnimationBlock;
