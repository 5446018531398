import React, {createContext, ReactNode, useCallback, useState} from "react";
import {StepIntroduction} from "../types/steps-introduction.interface";
import LocalStorageService from "../../core/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";

interface StepperContextType {
    currentStep: StepIntroduction;
    nextStep: () => void;
    previousStep: () => void;
}

export const StepperIntroductionContext = createContext<StepperContextType | undefined>(undefined);

export const StepperIntroductionProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [currentStep, setCurrentStep] = useState<StepIntroduction>(() => {
        const savedStep = LocalStorageService.get(LocalStorageKeys.LAST_STEP_INTRODUCTION) as StepIntroduction;
        return savedStep || StepIntroduction.STEP_ONE;
    });

    const nextStep = useCallback(() => {
        setCurrentStep((prevStep) => {
            if (prevStep < StepIntroduction.STEP_THREE) {
                const nextStep = (prevStep + 1) as StepIntroduction;
                LocalStorageService.save(LocalStorageKeys.LAST_STEP_INTRODUCTION, nextStep);
                return nextStep;
            }
            return prevStep;
        });
    }, []);

    const previousStep = useCallback(() => {
        setCurrentStep((prevStep) => {
            if (prevStep > StepIntroduction.STEP_ONE) {
                const prevStepUpdated = (prevStep - 1) as StepIntroduction;
                LocalStorageService.save(LocalStorageKeys.LAST_STEP_INTRODUCTION, prevStepUpdated);
                return prevStepUpdated;
            }
            return prevStep;
        });
    }, []);

    const contextValue = {
        currentStep,
        nextStep,
        previousStep
    };

    return (
        <StepperIntroductionContext.Provider value={contextValue}>
            {children}
        </StepperIntroductionContext.Provider>
    );
};
