import {useState} from "react";
import AreaApiService from "../services/area-api.service";
import {MainArea} from "../types/main-area.interface";
import {AxiosError} from "axios";
import {Subarea} from "../types/sub-area.interface";
import {sortByOrdinalNumber} from "../../common/helpers/sort-by-ordinal-number";
import {Chapter} from "../types/chapter.interface";

const UseAreaApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const fetchMainAreas = async (): Promise<Array<MainArea>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getMainAreas()
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const updateAreasStatus = async (): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.updateStatusAreas()
            .then()
            .catch()
            .finally();
    };

    const fetchSubareas = async (areaName: string): Promise<Array<Subarea>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getSubAreas(areaName)
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const fetchChapters = async (subareaName: string): Promise<Array<Chapter>> => {
        setLoading(true);
        setError(null);

        return await AreaApiService.getChapters(subareaName)
            .then(res => sortByOrdinalNumber(res))
            .catch((err): [] => {
                setError(err);
                return []
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    return {loading, error, fetchMainAreas, fetchSubareas, fetchChapters,updateAreasStatus}
}

export default UseAreaApi
