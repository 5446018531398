import {IDBPDatabase, openDB} from 'idb';
import NotificationService from './notification.service';
import {DBConfig} from "../types/db-config.interface";
import {CustomDBRequestConfig} from "../types/custom-db-request-config.interface";


export class DatabaseService {
    private dbPromise: Promise<IDBPDatabase>;

    constructor(config: DBConfig) {
        this.dbPromise = openDB(config.dbName, config.version, {
            upgrade(db) {
                for (const storeName in config.stores) {
                    if (!db.objectStoreNames.contains(storeName)) {
                        const {keyPath, autoIncrement} = config.stores[storeName];
                        db.createObjectStore(storeName, {keyPath, autoIncrement});
                    }
                }
            },
        });
    }

    private async handleRequest<T>(
        operation: () => Promise<T>,
        config?: CustomDBRequestConfig
    ): Promise<T> {
        try {
            const result = await operation();
            if (config?.successMessage) {
                NotificationService.success(config.successMessage);
            }
            return result;
        } catch (error) {
            const customMessage: string = config?.errorMessage || 'An unexpected error occurred';
            NotificationService.error(customMessage, error);
            throw error;
        }
    }

    async add<T>(storeName: string, value: T, config?: CustomDBRequestConfig): Promise<IDBValidKey> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;
            return db.add(storeName, value);
        }, config);
    }

    async getAll<T>(storeName: string, config?: CustomDBRequestConfig): Promise<T[]> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;
            return db.getAll(storeName);
        }, config);
    }

    async getById<T>(storeName: string, id: number, config?: CustomDBRequestConfig): Promise<T | undefined> {
        return this.handleRequest(async () => {
            const db = await this.dbPromise;
            return db.get(storeName, id);
        }, config);
    }

    async update<T>(storeName: string, value: T, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.put(storeName, value);
        }, config);
    }

    async delete(storeName: string, id: number, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.delete(storeName, id);
        }, config);
    }

    async clear(storeName: string, config?: CustomDBRequestConfig): Promise<void> {
        await this.handleRequest(async () => {
            const db = await this.dbPromise;
            await db.clear(storeName);
        }, config);
    }
}

const databaseService = new DatabaseService({
    dbName: 'psycho-app',
    version: 1,
    stores: {
        notes: {keyPath: 'id', autoIncrement: true},
    },
});

export default databaseService;
