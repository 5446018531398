import React from "react";
import {BaseBlock} from "../types/base-block.interface";
import {INextStepBlock} from "../types/blocks/next-step-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import {usePageScenario} from "../hooks/use-page-scenario";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";

const NextStepBlock: React.FC<BaseBlock<INextStepBlock>> = (props: BaseBlock<INextStepBlock>) => {
    const {data, id, blockCode, readonly} = props;
    const {connectWithApi} = data;
    const {sendUserAnswer} = useDynamicViewApi();
    const {nextPage, prevPage, currentPageIndex} = usePageScenario()

    const handleClick = () => {
        nextPage().then(res => {
            if (res && connectWithApi && !readonly) {
                sendUserAnswer(id, blockCode, {})
                    .then(() => nextPage())
                    .catch()
                    .finally()
            }
        })
    }

    return (
        <div className='flex gap-5 items-center mt-10'>
            {(readonly && currentPageIndex > 0 )&& (
                <button
                    onClick={prevPage}
                    className='w-fit h-fit bg-gray-50 flex items-center justify-center rounded-full border border-gray-300 p-3
                    max-h-[44px] max-w-[44px] hover:text-primary_700 focus:text-primary_700 '>
                    <ChevronLeftIcon className='w-5 h-5 text-gray-900'/>
                </button>
            )}
            <PrimaryButton
                styleClass='sm:w-[600px] w-full mx-auto'
                onClick={handleClick}
                icon={<ArrowRightIcon className='w-5 h-5 ml-2'/>}>
                Przejdź dalej
            </PrimaryButton>
        </div>
    )
}

export default NextStepBlock
