import React, {ComponentType} from 'react';
import {Navigate} from "react-router-dom";
import useAuth from "../../auth/hooks/useAuth";

interface WithIntroductionGuardProps {
}

const withIntroductionGuard = <P extends WithIntroductionGuardProps>(WrappedComponent: ComponentType<P>) => {
    return (props: P) => {
        const {currentUser} = useAuth();

        if (!currentUser) {
            return <Navigate to="/login" replace/>;
        }

        if (!currentUser.hasCompletedIntroduction) {
            return <Navigate to="/main/introduction" replace/>;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withIntroductionGuard;
