import React, {useEffect, useRef} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import {IShortAnswerBlock} from "../types/blocks/short-answer-block.interface";
import TaskHeader from "../components/ui/task-header";
import {useFormManager} from "../../core/hooks/use-form-manager";
import * as Yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import TextareaFormField from "../../core/components/forms/textarea-form-field";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import {yupResolver} from "@hookform/resolvers/yup";
import {defaultFormConfig} from "../constants/default-form-config";

interface FormValues {
    content: string;
}

const ShortAnswerBlock: React.FC<BaseBlock<IShortAnswerBlock>> = (props: BaseBlock<IShortAnswerBlock>) => {
    const {data, interactiveTaskNumber, id, blockCode, readonly} = props;
    const {userAnswer, question} = data;
    const {registerForm, unregisterForm, validateForms} = useFormManager();
    const {sendUserAnswer} = useDynamicViewApi();

    const schema = Yup.object().shape({
        content: Yup
            .string()
            .max(200, 'Odpowiedź nie może przekraczać 200 znaków.')
            .required('Proszę uzupełnić pole.'),
    }).required();

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            content: userAnswer || ''
        },
        disabled: readonly
    });

    const originalValueRef = useRef(methods.getValues('content'));
    const value = methods.watch('content');

    const onSubmit = (data: FormValues) => {
        if (data.content && data.content.length <= 200 && value !== originalValueRef.current) {
            originalValueRef.current = value;
            sendUserAnswer(id, blockCode, data)
                .then()
                .catch()
                .finally()
        }
    };

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    return (
        <FormProvider {...methods}>
            <form>
                <TaskHeader numberOfTask={interactiveTaskNumber} text={question}/>
                <TextareaFormField
                    id={id.toString()}
                    onFocus={() => methods.clearErrors()}
                    onBlur={() => onSubmit(methods.getValues())}
                    name="content"
                    label=""
                    maxChar={200}
                    placeholder="Zacznij pisać tutaj..."
                />
            </form>
        </FormProvider>
    );
}

export default ShortAnswerBlock;
