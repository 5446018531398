import { useLocation, useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";

const BreadcrumbArea = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const pathnames: string[] = location.pathname
        .split('/')
        .filter((x) => decodeURIComponent(x))

    if (pathnames.length < 2 || pathnames.length === 4) return null;

    return (
        <nav className="flex items-end space-x-4 mb-8 justify-between sm:mb-12">
            <div className="flex">
                <header className='w-full text-dark font-bold text-2xl flex flex-col'>
                        <span className='text-gray-400 text-sm font-semibold leading-5'>{decodeURIComponent(pathnames[2] || '')}</span>
                        <span>{decodeURIComponent(pathnames[1] || '')}</span>
                </header>
            </div>
            <button onClick={() => navigate(-1)}
                    className="bg-white border-[1px] border-gray-300 w-11 hover:text-primary_50 duration-100
                    h-11 rounded-full flex items-center justify-center">
                <ChevronLeftIcon className='w-6 h-6 text-gray-900'/>
            </button>
        </nav>
    );
}

export default BreadcrumbArea
