import {useState} from "react";
import {AxiosError} from "axios";
import SupportApiService from "../services/support-api.service";
import {UrgentHelp} from "../types/urgent-help";

const UseSupportApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);

    const getSupportDetails = async (): Promise<UrgentHelp> => {
        setLoading(true);
        setError(null);

        return await SupportApiService.getUrgentHelpDetails()
            .then()
            .catch(err => {
                setError(err)
                return {} as UrgentHelp
            })
            .finally((): void => setLoading(false))
    };

    return {loading, error, getSupportDetails}
}

export default UseSupportApi
