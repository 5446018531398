import React, {useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";

interface PasswordFormFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    name: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    showPasswordToggle?: boolean;
}

const PasswordFormField: React.FC<PasswordFormFieldProps> = (props: PasswordFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        showPasswordToggle = true,
        ...rest
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="relative">
            <label htmlFor={name} className="block text-gray-700 text-sm">{label}</label>
            {isLoading ? (
                <SkeletonFormField/>
            ) : (
                <div className="relative">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        id={name}
                        {...register(name)}
                        className={`w-full px-3 py-[8px] text-sm border rounded-lg shadow-sm focus:outline-none focus:ring-2 ${errors[name] ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                        disabled={isSubmitting}
                        {...rest}
                    />
                    {showPasswordToggle && (
                        <div
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <EyeSlashIcon
                                    className={`h-4 w-4 font-bold 
                                    ${errors[name] ? 'text-red-500' : 'text-gray-500'}`}/>
                            ) : (
                                <EyeIcon className={`h-4 w-4 font-bold 
                                    ${errors[name] ? 'text-red-500' : 'text-gray-500'}`}/>
                            )}
                        </div>
                    )}
                </div>
            )}
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default PasswordFormField;
