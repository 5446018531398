import ApiService from "../../core/services/api.service";
import {IntroductionFormBody} from "../types/introduction-form.interface";

class IntroductionApiService extends ApiService {
    constructor() {
        super();
    }

    public async sendUserData(userId: string, body: IntroductionFormBody): Promise<void> {
        return await this.put<void>(`/users/introduction/${userId}`, body, {
            skipDefault404Handling: true,
        })
    }
}

export default new IntroductionApiService();
