import ApiService from "../../core/services/api.service";
import {UrgentHelp} from "../types/urgent-help";

class SupportApiService extends ApiService {

    constructor() {
        super();
    }

    public async getUrgentHelpDetails(): Promise<UrgentHelp> {
        return await this.get<UrgentHelp>('/urgent-help', {})
            .then(res => res)
    }
}

export default new SupportApiService();
