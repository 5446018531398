import React, {useEffect, useState} from 'react';
import {Week} from "../types/week.interface";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import ScheduleService from "../services/schedule.service";
import CalendarService from "../services/calendar.service";
import useCalendarApi from "../hooks/use-calendar-api";
import ScheduleDayView from "./schedule-day-view";
import {CalendarEvent} from "../types/calendar-event.interface";
import {getSeason} from "../helpers/get-season";
import {useTheme} from "../../core/hooks/use-theme";
import {useCalendarForm} from "../hooks/use-calendar-form";

const Schedule: React.FC = () => {
    const [schedule, setSchedule] = useState<Week[]>([]);
    const [currentMonthAndYear, setCurrentMonthAndYear] = useState('');
    const [_, setCurrentDay] = useState<number | null>(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const {getCalendarData} = useCalendarApi();
    const { theme } = useTheme();
    const {refreshKey} = useCalendarForm();

    useEffect(() => {
        const updateSchedule = async () => {
            setCurrentMonthAndYear(CalendarService.getMonthAndYear(currentDate));
            setCurrentDay(CalendarService.getCurrentDay());

            const [start, end] = ScheduleService.getMonthStartAndEndDates(currentDate);
            const apiData = await getCalendarData(start, end);

            const generatedSchedule = generateSchedule(start, end, apiData);
            setSchedule(generatedSchedule);
        };

        updateSchedule();
    }, [currentDate,refreshKey]);

    const generateSchedule = (start: Date, end: Date, apiData: CalendarEvent[]): Week[] => {
        return ScheduleService
            .generateEmptySchedule(start)
            .map(week => ({
                ...week,
                days: week.days.map(day => ({
                    ...day,
                    tasks: ScheduleService
                        .getTasksForDay(day.date!, apiData, currentDate)
                        .sort(ScheduleService.sortTasksByTime(currentDate)),
                })),
            }))
            .filter(week => week.days.some(day => day.tasks.length > 0))
    };

    const handleMonthChange = (increment: number) => {
        setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + increment, 1));
    };

    const getBackgroundClass = () => {
        const month = currentDate.getMonth();
        const season = getSeason(month);
        return `bg-${season}-${theme}`;
    };

    return (
        <div className="rounded-lg">
            <div className={`flex justify-between my-4 py-6 px-3 bg-gray-200 text-2xl leading-8 font-bold ${getBackgroundClass()}`}>
                <button onClick={() => handleMonthChange(-1)}>
                    <ChevronLeftIcon className="w-6 h-6 text-gray-600 theme-dark:text-gray-50"/>
                </button>
                <span className='first-letter:uppercase'>{currentMonthAndYear}</span>
                <button onClick={() => handleMonthChange(1)}>
                    <ChevronRightIcon className="w-6 h-6 text-gray-600 theme-dark:text-gray-50"/>
                </button>
            </div>
            {schedule.length > 0 ? (
                schedule.map((week: Week, weekIndex: number) => (
                    <div key={weekIndex} className="mb-6">
                        <div className="mb-2 text-xl leading-8 font-normal">{week.label}</div>
                        {week.days
                            .filter(day => day.tasks.length > 0)
                            .map((day, i) => <ScheduleDayView key={i} day={day} index={i}/>)
                        }
                    </div>
                ))
            ) : (
                <div className="text-center text-gray-500 text-xl mt-10 theme-dark:text-gray-50">
                    Brak wydarzeń / zadań w tym miesiącu.
                </div>
            )}
        </div>
    );
};

export default Schedule;
