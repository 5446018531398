import React, {useRef} from 'react';
import {MainArea} from "../types/main-area.interface";
import {LockClosedIcon, PhotoIcon} from "@heroicons/react/24/outline";
import ProgressRing from "./progress-ring";
import useElementStates from "../../core/hooks/use-element-states";
import {useNavigate} from "react-router-dom";
import {PrefixBase64} from "../../core/constants/prefixBase64";
import StatusLabel from "../../common/components/status-label";
import {CheckIcon} from "@heroicons/react/24/solid";

const Area: React.FC<MainArea> = (props) => {
    const refArea = useRef<HTMLElement>(null)
    const {isPressed, isHovered} = useElementStates({ref: refArea})
    const navigate = useNavigate();

    const goToSubareas = () => navigate(`/main/${encodeURIComponent(props.name)}`);

    const hasImage = (): boolean => !!props.image.imageBase64;

    const isCourseWithoutAccess = (): boolean => props.course && !props.hasAccess;

    const hasCourseWithAccess = (): boolean => props.course && props.hasAccess;

    const shouldRenderProgressRing = (): boolean => !props.course || hasCourseWithAccess();

    return (
        <article
            onClick={goToSubareas.bind(this)}
            ref={refArea}
            className={`rounded-lg flex sm:flex-wrap w-full sm:rounded-lg sm:overflow-hidden bg-white sm:max-h-80 max-h-[110px]
            overflow-hidden shadow-lg ring-black ring-opacity-5 ring-1 
            transform transition-transform duration-300 cursor-pointer border-primary_600 box-border
            ${isHovered ? 'border-[1px]' : ''} cursor-pointer
            ${isPressed ? 'border-2' : ''} cursor-pointer`}
        >
            <div
                className='hidden xs:block relative sm:w-full rounded-l-lg sm:rounded-none sm:h-60 bg-gray-200 w-28 min-w-28'>
                {hasImage() ? (
                    <img
                        className='w-full h-full object-cover'
                        alt={props.name}
                        src={PrefixBase64 + props.image.imageBase64}
                    />
                ) : (
                    <PhotoIcon className='w-full h-full text-gray-500'/>
                )}
            </div>
            <header className='flex flex-col rounded-l-lg overflow-hidden flex-grow m-4 h-fit gap-1'>
                <h6 className='text-gray-900 font-semibold text-md truncate'>
                    {props.name}
                </h6>
                <span className='flex justify-between flex-wrap gap-x-1 gap-y-2'>
                    {isCourseWithoutAccess() ? (
                        <>
                            <StatusLabel
                                text='Kurs'
                                icon={<LockClosedIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit h-fit py-[1px] font-medium leading-4 text-xs bg-white text-green-600 border border-green-500'
                            />
                            <div className="flex justify-end flex-grow">
                                <StatusLabel
                                    text='Wykup dostęp'
                                    classNameIcon='w-3 h-3'
                                    className='w-fit h-fit px-4 py-[7px] font-medium leading-4 text-xs bg-white text-green-700 shadow-sm !bg-primary_100'
                                />
                            </div>
                        </>
                    ) : (
                        props.course && (
                            <StatusLabel
                                text='Twój kurs'
                                icon={<CheckIcon/>}
                                classNameIcon='w-3 h-3'
                                className='w-fit font-medium leading-4 text-xs bg-white text-green-600 border border-green-500'
                            />
                        )
                    )}
                </span>
            </header>
            {shouldRenderProgressRing() && (
                <aside className='w-fit flex items-center justify-center sm:mb-2 min-w-max'>
                    <ProgressRing radius={36} progress={props.isCompleted} stroke={6} isCompleted={props.isCompleted === 100}/>
                </aside>
            )}
        </article>
    );
}

export default Area;
