import React, {useRef} from 'react';
import useElementStates from "../../core/hooks/use-element-states";
import {Chapter as IChapter} from "../types/chapter.interface";
import {useNavigate, useParams} from "react-router-dom";
import Completed from "./completed";
import {TypeParent} from "../../view-builder/types/type-parent.interface";
import {useDynamicViewApi} from "../../view-builder/hooks/use-dynamic-view-api";
import {useRetryRequest} from "../../core/hooks/use-retry-request";

const CardChapter: React.FC<IChapter> = (props) => {
    const refChapter = useRef<HTMLElement>(null)
    const {isPressed, isHovered} = useElementStates({ref: refChapter})
    const navigate = useNavigate();
    const params = useParams();
    const {createPureBlocks} = useDynamicViewApi();
    const retryRequest = useRetryRequest();

    const goToChapter = () => {
        retryRequest(
            () => createPureBlocks(props.name, TypeParent.CHAPTER).then(),
            {delay: 500, maxRetries: 3}
        )
            .then()
            .catch(() => {
            })
            .finally()
        navigate(`/main/${params['area']}/${params['subarea']}/${encodeURIComponent(props.name)}`, {
            state: {isCompleted: props.isCompleted}
        });
    }

    return (
        <article
            ref={refChapter}
            onClick={goToChapter}
            className={`relative rounded-2xl flex sm:max-h-80 max-h-[80px] sm:min-w-72
            overflow-hidden shadow-lg ring-black ring-opacity-5 ring-1 focus:bg-primary_50
            transform transition-transform duration-300  cursor-pointer border-primary_600 box-border
            ${isHovered ? 'bg-primary_50' : 'bg-white'} cursor-pointer
            ${isPressed ? 'border-2 !bg-primary_50' : 'border-[1px]'} cursor-pointer`}
        >
            <header className='flex gap-4 rounded-l-lg overflow-hidden flex-grow m-4'>
                <h6 className='text-gray-900 font-semibold text-md break-words truncate'>
                    {props.name}
                </h6>
            </header>
            <aside className='relative mt-5 mr-4'>
                <Completed isCompleted={props.isCompleted}/>
            </aside>
        </article>
    );
}

export default CardChapter;
