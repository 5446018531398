import {useAppBackground} from "../modules/settings/hooks/use-app-background";
import {useState} from "react";
import {OverlaySidebarMenuProvider} from "../modules/core/contexts/overlay-sidebar-menu-context";
import HeaderLayer from "./app-header";
import {Navigate, Route, Routes} from "react-router-dom";
import SideBarLayer from "./app-sidebar";
import NotesView from "../pages/notes";
import MyProfile from "../pages/my-profile";
import Dashboard from "../pages/dashboard";
import AppWrapper from "./app-wrapper";
import Support from "../pages/support";
import withIntroductionGuard from "../modules/core/hoc/withIntroductionGuard";
import Calendar from "../pages/calendar";
import EmailConfirmation from "../modules/auth/components/email-confirmation";
import useAuth from "../modules/auth/hooks/useAuth";


const MainLayer = () => {
    const {currentBackground} = useAppBackground();
    const {hasEmailConfirmed} = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenu = (value: boolean) => {
        setIsMenuOpen(value);
    }

    return (
        <OverlaySidebarMenuProvider>
            <div className={'w-full bg-gray-50 h-svh flex flex-col ' + currentBackground.bgBackdropClasses}>
                <HeaderLayer handleOpenMenu={handleMenu}/>
                <AppWrapper className='w-full flex-grow overflow-hidden'>
                    <Routes>
                        <Route path='/*' element={<Dashboard/>}/>
                        <Route path="profile" element={<MyProfile/>}/>
                        <Route path="notes" element={<NotesView/>}/>
                        <Route path="support" element={<Support/>}/>
                        <Route path="calendar" element={<Calendar/>}/>
                        <Route path="*" element={<Navigate to="/not-found"/>}/>
                    </Routes>
                    {!hasEmailConfirmed() && <EmailConfirmation/>}
                </AppWrapper>
                <SideBarLayer isMenuOpen={isMenuOpen}/>
            </div>
        </OverlaySidebarMenuProvider>
    )
}
export default withIntroductionGuard(MainLayer)
