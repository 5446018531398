import React, {useRef} from "react";
import {BaseBlock} from "../types/base-block.interface";
import PrimaryButton from "../../common/components/primary-button";
import {IEndBlock} from "../types/blocks/end-block.interface";
import PromptAction from "../../common/components/prompt-action";
import {PromptActionHandle} from "../../common/types/prompt-action-handle.interface";
import {CheckCircleIcon, StarIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
import {useFormManager} from "../../core/hooks/use-form-manager";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {usePageScenario} from "../hooks/use-page-scenario";
import {useScenario} from "../hooks/use-scenario";
import {useStepperIntroduction} from "../../introduction/hooks/use-steps-introduction";

const EndBlock: React.FC<BaseBlock<IEndBlock>> = (props: BaseBlock<IEndBlock>) => {
    const {data, readonly} = props;
    const {label = "Zakończ rozdział"} = data
    const promptRef = useRef<PromptActionHandle>(null);
    const navigate = useNavigate();
    const {validateForms, scrollToInvalidForm} = useFormManager();
    const {prevPage, currentPageIndex} = usePageScenario();
    const {typeParent, endAction} = useScenario();

    const handleClick = async () => {
        if (typeParent === "CHAPTER") {
            if (!readonly) {
                if (await validateForms()) {
                    promptRef?.current?.open();
                } else {
                    scrollToInvalidForm();
                }
            } else {
                navigate(-1);
            }
        } else {
            if (await !validateForms()) {
                scrollToInvalidForm();
            } else {
                if (endAction) endAction();
            }
        }
    }

    const handleConfirm = () => {
        navigate(-1);
    }

    return (
        <>
            <div className='flex gap-5 items-center mt-10'>
                {(readonly && currentPageIndex > 0) && (
                    <button
                        onClick={prevPage}
                        className='w-fit h-fit bg-gray-50 flex items-center justify-center rounded-full border border-gray-300 p-3
                    max-h-[44px] max-w-[44px] hover:text-primary_700 focus:text-primary_700 '>
                        <ChevronLeftIcon className='w-5 h-5 text-gray-900'/>
                    </button>
                )}
                <PrimaryButton
                    styleClass='sm:w-[600px] py-3 w-full mx-auto'
                    onClick={handleClick}
                    icon={<CheckCircleIcon className='w-5 h-5 ml-2'/>}>
                    {label}
                </PrimaryButton>
            </div>
            <PromptAction
                ref={promptRef}
                showClose={false}
                showReject={false}
                confirmText='Przejdź dalej!'
                variant='success'
                title='Rozdział ukończony'
                customIcon={<StarIcon/>}
                message='Gratulacje! Ukończyłeś ten rozdział. Gotowy, aby przejść do kolejnego etapu?'
                onConfirm={handleConfirm}/>
        </>

    )
}

export default EndBlock
