import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ThemeProvider} from "./modules/core/contexts/theme-context";
import {BackgroundProvider} from "./modules/settings/contexts/background-context";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <BackgroundProvider>
                <App/>
            </BackgroundProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to registerAccount() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
