import {useEffect, useRef} from "react";
import {PromptActionHandle} from "../../common/types/prompt-action-handle.interface";
import PromptAction from "../../common/components/prompt-action";
import {CheckCircleIcon, TrashIcon} from "@heroicons/react/24/outline";
import Modal from "../../common/components/modal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import {useModal} from "../../common/hooks/useModal";

interface ChapterResetPromptProps {
    onCancel: () => void;
}

const ChapterResetPrompt = (props: ChapterResetPromptProps) => {
    const {onCancel} = props
    const resetPromptRef = useRef<PromptActionHandle>(null)
    const {openModal, closeModal} = useModal();

    const handleConfirm = () => {
        openModal(ModalIds.SUCCESS_CLEAR_CHAPTER);
    }

    const handleReject = () => {
        onCancel();
    }

    useEffect(() => {
        resetPromptRef.current?.open();
        return () => resetPromptRef.current?.close();
    }, []);

    return (
        <div>
            <PromptAction
                ref={resetPromptRef}
                title='Na pewno usunąć odpowiedzi?'
                cancelText='Anuluj'
                confirmText='Usuń'
                variant='error'
                showClose={false}
                customIcon={<TrashIcon/>}
                message='Jeśli zresetujesz odpowiedzi utracisz je bezpowrotnie.'
                onConfirm={handleConfirm}
                onReject={handleReject}
                showConfirm={false}
            />
            <Modal id={ModalIds.SUCCESS_CLEAR_CHAPTER} classModal='!min-h-0 !p-5 !border-gray-300'>
                <div className='flex gap-3 items-center '>
                    <CheckCircleIcon className='w-5 h-5'/>
                    <span className='text-lg leading-6 font-semibold'>Odpowiedzi zostały usunięte</span>
                </div>
            </Modal>
        </div>
    )
}

export default ChapterResetPrompt
