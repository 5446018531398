import Area from "./area";
import {useEffect, useState} from "react";
import {MainArea} from "../types/main-area.interface";
import useAreaApi from "../hooks/use-area-api";
import EmptyList from "./empty-list";
import useAuth from "../../auth/hooks/useAuth";
import AreaSkeleton from "./area-skeleton";


const ListAreas = () => {
    const [areas, setAreas] = useState<MainArea[]>([])
    const [isEmpty, setIsEmpty] = useState(false);
    const {fetchMainAreas, loading, updateAreasStatus} = useAreaApi();
    const {currentUser} = useAuth();

    useEffect(() => {
        updateAreasStatus()
            .then(() => {
                fetchMainAreas()
                    .then(res => {
                        res.length && setAreas(res || [])
                        if (!res.length) setIsEmpty(true)
                    })
            })
    }, [])

    const renderAreas = () => areas.map((area, id) => <Area key={id} {...area} />)

    return (
        <>
            <header className='w-full text-dark font-bold text-lg mb-8'>
                <span>Cześć, {currentUser?.firstname ? currentUser.firstname + ',' : ''}</span>
                <br/>
                <span>nad czym dziś pracujemy?</span>
            </header>
            {!isEmpty
                ? (
                    <section
                        className='grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8'>
                        {!loading ? (
                            renderAreas()
                        ) : (
                            <>
                                {Array.from({length: 5}).map((i, index) => <AreaSkeleton key={index}/>)}
                            </>
                        )}
                    </section>
                )
                : <EmptyList content='Brak obszarów.'/>
            }
        </>
    )
}

export default ListAreas
