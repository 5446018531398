import {useState} from 'react';
import {sortByOrdinalNumber} from "../../common/helpers/sort-by-ordinal-number";
import {AxiosError} from "axios";
import DynamicViewsApiService from "../services/dynamic-views-api.service";
import {BlockDTO} from "../types/get-blocks-response.interface";
import {useParams} from "react-router-dom";
import {TypeParent} from "../types/type-parent.interface";
import {BLOCK_CODE} from "../types/block-codes.interface";

export const useDynamicViewApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);
    const params = useParams();

    const fetchBlocksDTO = async (typeParent: TypeParent, parentName?: string): Promise<Array<BlockDTO>> => {
        setLoading(true);
        setError(null);
        const parentNameFromURL: string = getParentName();
        parentName = parentName ? parentName : parentNameFromURL

        return await DynamicViewsApiService.getBlocks(parentName, typeParent)
            .then(res => sortByOrdinalNumber(res))
            .catch()
            .finally((): void => {
                setLoading(false)
            })
    };

    const sendUserAnswer = async (blockId: number, blockCode: BLOCK_CODE, body: any) => {
        const path: string = mapBlockCodeToPath(blockCode);

        return await DynamicViewsApiService.updateBlock(blockId, path, body)
            .then()
            .catch(err => console.error(err))
            .finally((): void => {
                setLoading(false)
            })
    }

    const createPureBlocks = async (parentName: string, typeParent: TypeParent) => {
        return await DynamicViewsApiService.createPureUserBlocks(parentName, typeParent)
            .then()
            .catch(err => {
                setError(err)
                throw err
            })
            .finally()
    }

    function getParentName(): string {
        return String(params['chapter'] || params['screen']);
    }

    function mapBlockCodeToPath(codeBlock: BLOCK_CODE): string {
        return codeBlock.toLowerCase().replaceAll('_', '-');
    }

    return {loading, setLoading, error, fetchBlocksDTO, sendUserAnswer, createPureBlocks};
};
