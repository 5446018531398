import {ReactNode} from "react";


type AppWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppWrapper = (props: AppWrapperProps) => {
    const {children, className} = props
    return (
        <div className={'mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative flex-grow '+className}>
            {children}
        </div>
    )
}

export default AppWrapper
