import React, {createContext, useEffect, useState} from 'react';
import {AuthContextType} from "../types/authContextType.interface";
import {AuthProviderProps} from "../types/authProviderProps.interface";
import AuthService from "../services/auth.service";
import {AxiosError} from "axios";
import UserApiService from "../../core/services/user-api.service";
import {useNavigate} from "react-router-dom";
import LocalStorageService from "../../core/services/local-storage.service";
import {LocalStorageKeys} from "../../core/types/local-storage-keys.interface";
import {ResetPasswordBodyRequest} from "../types/reset-email-body.interface";
import {AuthenticatedUser} from "../types/authenticated-user.interface";
import {ChangePasswordBody, ChangePasswordBodyRequest} from "../types/change-password-body.interface";
import {Authentication} from "../types/authentication.interface";

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated());
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser | undefined>(AuthService.authenticatedUser);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AxiosError | null>(null);
    const navigate = useNavigate();

    const login = async (email: string, password: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.authenticate(email, password)
            .then((): void => {
                setCurrentUser(AuthService.authenticatedUser!)
                setIsAuthenticated(true);
            })
            .catch((err): void => {
                setError(err);
                setIsAuthenticated(false);
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const handleAdminTokenLogin = async (token: string): Promise<void> => {
        setLoading(true);
        setError(null);

        return await AuthService.handleAdminTokenLogin(token)
            .then((): void => {
                setIsAuthenticated(true);
            })
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const register = async (email: string, password: string) => {
        setLoading(true);
        setError(null);

        return await UserApiService.registerAccount(email, password)
            .then(() => {
                navigate('/login', {replace: true})
            })
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    };

    const logout = () => {
        setIsAuthenticated(false);
        setCurrentUser(undefined);
        AuthService.logout();
    };

    const getTokenToResetPassword = async (email: string) => {
        setLoading(true);
        setError(null);

        return await AuthService.getTokenResetPassword(email)
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const resetPassword = async (token: string, newPassword: string): Promise<void> => {
        setLoading(true);
        setError(null);

        const body: ResetPasswordBodyRequest = {
            email: LocalStorageService.get(LocalStorageKeys.EMAIL_RESET_PASSWORD),
            token,
            newPassword
        }

        return await AuthService.resetPassword(body)
            .then((): void => {
                navigate('/login', {replace: true})
            })
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const changePassword = async (body: ChangePasswordBody): Promise<void> => {
        setLoading(true);
        setError(null);

        const mappedBody: ChangePasswordBodyRequest = {
            currentPassword: body.currentPassword,
            newPassword: body.newPassword
        }

        return await AuthService.changePassword(mappedBody)
            .then()
            .catch((err): void => {
                setError(err);
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const sendEmailConfirmationToken = async (email: string): Promise<void | AxiosError> => {
        setLoading(true);
        setError(null);

        return await AuthService.sendEmailConfirmationToken(email)
            .then()
            .catch((err: AxiosError) => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const activateAccount = async (userId: string, token: string): Promise<void | AxiosError> => {
        setLoading(true);
        setError(null);

        return await AuthService.activateAccount(userId, token)
            .then()
            .catch((err: AxiosError) => {
                setError(err);
                throw err;
            })
            .finally((): void => {
                setLoading(false)
            })
    }

    const updateAuthenticationField = <K extends keyof Authentication>(key: K, value: Authentication[K]) => {
        AuthService.updateAuthenticationField(key, value);
        if (key === 'user') {
            setCurrentUser(AuthService.authenticatedUser);
        }
    };

    const hasEmailConfirmed = () => !!currentUser?.emailConfirmed;

    useEffect(() => {
        //TODO propably to delete
        if (!AuthService.authenticatedUser) {
            setIsAuthenticated(false);
        }
    }, [AuthService.authenticatedUser]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                handleAdminTokenLogin,
                login,
                logout,
                getTokenToResetPassword,
                resetPassword,
                changePassword,
                register,
                loading,
                error,
                currentUser,
                setCurrentUser,
                updateAuthenticationField,
                hasEmailConfirmed,
                sendEmailConfirmationToken,
                activateAccount
            }}>
            {children}
        </AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider}
