import React from 'react';
import useAuth from "../hooks/useAuth";
import {Navigate, Outlet, RouteProps} from "react-router-dom"

const PrivateRoutes = (props: RouteProps) => {
    const {isAuthenticated, currentUser} = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace/>;
    }

    return <Outlet/>;
};
export default PrivateRoutes;
