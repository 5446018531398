import { Backgrounds } from "../types/background.interface";
import { createContext, ReactNode, useEffect, useState, useCallback } from "react";
import { backgrounds } from "../constants/backgrounds";
import { useTheme } from "../../core/hooks/use-theme";
import { Theme } from "../../core/types/theme.interface";
import LocalStorageService from "../../core/services/local-storage.service";
import { LocalStorageKeys } from "../../core/types/local-storage-keys.interface";

export interface BackgroundContextProps {
    currentBackground: {
        theme: Theme,
        bgBackdropClasses: string,
    };
    changeBackground: (bg: Backgrounds) => void;
    saveBackground: () => void;
    background: Backgrounds;
}

const BackgroundContext = createContext<BackgroundContextProps | undefined>(undefined);

const BackgroundProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const savedBackground = LocalStorageService.get(LocalStorageKeys.THEME_BACKGROUND) as Backgrounds | null;
    const initialBackground = savedBackground || Backgrounds.NONE;
    const [background, setBackground] = useState<Backgrounds>(initialBackground);
    const { setTheme } = useTheme();

    const changeBackground = (newBackground: Backgrounds): void => {
        setBackground(newBackground);
    };

    const saveBackground = () => {
        const theme: Theme = backgrounds[background].theme;
        setTheme(theme);
        LocalStorageService.save(LocalStorageKeys.THEME_BACKGROUND, background);
    };

    useEffect(() => {
        const theme: Theme = backgrounds[background].theme;
        setTheme(theme);
    }, []);

    const currentBackground =  backgrounds[background];

    return (
        <BackgroundContext.Provider value={{ currentBackground, changeBackground, saveBackground, background }}>
            {children}
        </BackgroundContext.Provider>
    );
};

export { BackgroundContext, BackgroundProvider };
