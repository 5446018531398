import Modal from "../../common/components/modal";
import {ModalIds} from "../../common/constants/modal-ids.interface";
import withModalProvider from "../../common/hoc/withModalProvider";
import {useEffect, useState} from "react";
import {useModal} from "../../common/hooks/useModal";
import {InboxIcon} from "@heroicons/react/24/outline";
import PrimaryButton from "../../common/components/primary-button";
import useAuth from "../hooks/useAuth";

const EmailConfirmation = () => {
    const [success, setSuccess] = useState<boolean>(false);
    const {sendEmailConfirmationToken, currentUser} = useAuth();
    const {openModal, closeModal} = useModal();

    const handleSendEmailConfirmationToken = () => {
        if (currentUser?.email) {
            sendEmailConfirmationToken(currentUser?.email)
                .then(() => setSuccess(true))
                .catch(() => {})
                .finally()
        }
    }

    useEffect(() => {
        openModal(ModalIds.EMAIL_CONFIRMATION)
    }, []);

    return (
        <div>
            <Modal id={ModalIds.EMAIL_CONFIRMATION} showClose={false} disableBackdropClick={true}>
                {!success
                    ? (
                        <section className='flex flex-col gap-6 items-center'>
                            <header className='flex flex-col gap-6 items-center'>
                                <InboxIcon className='w-6 h-6 text-green-600'/>
                                <h2 className='text-lg leading-6 font-semibold text-gray-900'>Potwierdź swój adres
                                    e-mail</h2>
                            </header>
                            <p className='text-sm leading-5 font-normal text-gray-500 text-center'>
                                Cieszymy się, że jesteś z nami. Już wkrótce rozpoczniesz Pozytywne Zmiany w swoim życiu.
                            </p>
                            <PrimaryButton onClick={handleSendEmailConfirmationToken}
                                           styleClass='max-w-fit h-fit py-1 px-3 text-sm leading-4 font-medium'>
                                Potwierdź adres e-mail
                            </PrimaryButton>
                        </section>
                    )
                    : (
                        <section className='flex flex-col gap-6 items-center'>
                            <header className='flex flex-col gap-6 items-center'>
                                <InboxIcon className='w-6 h-6 text-green-600'/>
                                <h2 className='text-lg leading-6 font-semibold text-gray-900'>
                                    E-mail został wysłany!
                                </h2>
                            </header>
                            <p className='text-sm leading-5 font-normal text-gray-500 text-center'>
                                Sprawdź swoją skrzynkę pocztową, aby potwierdzić adres e-mail. Jeśli nie znajdziesz
                                wiadomości, sprawdź folder spam.
                            </p>
                            <PrimaryButton onClick={handleSendEmailConfirmationToken}
                                           styleClass='max-w-fit h-fit py-1 px-3 text-sm leading-4 font-medium'>
                                Wyślij ponownie
                            </PrimaryButton>
                        </section>
                    )
                }

            </Modal>
        </div>
    )
}

export default withModalProvider(EmailConfirmation)
