import ProfileDataForm from "../modules/settings/components/profile-data-form";

const SettingsView = () => {

    return (
        <div className='h-full overflow-y-auto'>
            <ProfileDataForm/>
        </div>

    )
}

export default SettingsView
