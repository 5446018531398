import React, {Suspense} from 'react';
import {AuthProvider} from "./modules/auth/contexts/AuthContext";
import NotificationPrompt from "./modules/core/components/notification/notification-prompt";
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./app-routes";
import PageLoader from "./modules/common/components/page-loader";

function App() {
    return (
        <Router>
            <AuthProvider>
                <Suspense fallback={<PageLoader/>}>
                    <AppRoutes/>
                </Suspense>
                <NotificationPrompt/>
            </AuthProvider>
        </Router>
    );
}

export default App;
