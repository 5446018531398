import React, {useEffect, useRef, useState} from 'react';
import {BaseBlock} from "../types/base-block.interface";
import TaskHeader from "../components/ui/task-header";
import {IMultipleChoiceFixedCountBlock} from "../types/blocks/multiple-choice-fixed-count-block.interface";
import {FormProvider, useForm} from "react-hook-form";
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useFormManager} from "../../core/hooks/use-form-manager";
import {useDynamicViewApi} from "../hooks/use-dynamic-view-api";
import CheckboxFormField from "../../core/components/forms/checkbox-form-field";
import {defaultFormConfig} from "../constants/default-form-config";
import {DebounceTimeChecked} from "../constants/debounce-time-checked";
import {arraysEqual} from "../helpers/arrays-equal";

interface FormValues {
    selectedAnswers: number[];
}

const MultipleChoiceFixedCountBlock: React.FC<BaseBlock<IMultipleChoiceFixedCountBlock>> = (props: BaseBlock<IMultipleChoiceFixedCountBlock>) => {
    const {data, interactiveTaskNumber, id, blockCode, readonly} = props;
    const {selectedAnswers, answers, question, fixedCount} = data;
    const {registerForm, unregisterForm} = useFormManager();
    const {sendUserAnswer} = useDynamicViewApi();
    const originalValueRef = useRef<number[]>([]);
    const [currentSelectedAnswers, setCurrentSelectedAnswers] = useState<number[]>(() =>
        selectedAnswers?.length ? selectedAnswers.map(a => a.id) : []
    );

    const schema = Yup.object().shape({
        selectedAnswers: Yup
            .array()
            .of(Yup.number().required())
            .length(fixedCount, `Musisz zaznaczyć dokładnie ${fixedCount} ${fixedCount > 1 ? 'odpowiedzi' : 'odpowiedź'}.`)
            .required(),
    }).required();

    const methods = useForm<FormValues>({
        ...defaultFormConfig,
        resolver: yupResolver(schema),
        defaultValues: {
            selectedAnswers: currentSelectedAnswers,
        },
        disabled: readonly,
    });

    const onSubmit = (data: FormValues) => {
        if (data.selectedAnswers.length === fixedCount) {
            sendUserAnswer(id, blockCode, {blockContentIds: data.selectedAnswers})
                .then()
                .catch()
                .finally();
        }
    };

    const handleAnswerSelect = (selectedId: number) => {
        methods.clearErrors();
        setCurrentSelectedAnswers(prevSelected => {
            if (prevSelected.includes(selectedId)) {
                return prevSelected.filter(id => id !== selectedId);
            } else {
                if (prevSelected.length < fixedCount) {
                    return [...prevSelected, selectedId];
                }
                return prevSelected;
            }
        });
    };

    const selected = methods.watch('selectedAnswers');

    useEffect(() => {
        methods.setValue('selectedAnswers', currentSelectedAnswers);
    }, [currentSelectedAnswers, methods]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!arraysEqual(selected, originalValueRef.current) && !readonly) {
                onSubmit(methods.getValues());
                originalValueRef.current = [...selected];
            }
        }, DebounceTimeChecked);

        return () => clearTimeout(timer);
    }, [selected]);

    useEffect(() => {
        registerForm(id, schema, methods.getValues, methods.trigger);
        return () => unregisterForm(id);
    }, [id]);

    return (
        <FormProvider {...methods}>
            <form className='flex flex-col gap-3' id={id.toString()}>
                <TaskHeader numberOfTask={interactiveTaskNumber} text={question}/>
                {answers?.map((answer) => (
                    <CheckboxFormField
                        key={answer.id}
                        disabled={readonly}
                        name={`selectedAnswers`}
                        id={`option-${answer.id}`}
                        showError={false}
                        label={answer.name}
                        value={answer.id.toString()}
                        checked={currentSelectedAnswers.includes(answer.id)}
                        onChange={() => handleAnswerSelect(answer.id)}
                    />
                ))}
                {methods.formState.errors['selectedAnswers'] &&
                    <p className="text-red-500 text-xs">{'' + methods.formState.errors['selectedAnswers']!.message}</p>}
            </form>
        </FormProvider>
    );
}

export default MultipleChoiceFixedCountBlock;
