import MonthCalendarMobile from "../components/mobile/month-calendar-mobile";
import Schedule from "../components/schedule";
import React, {useEffect} from "react";
import {useCalendarForm} from "../hooks/use-calendar-form";

const MobileCalendar = () => {
    return (
        <div>
            <MonthCalendarMobile/>
            <Schedule/>
        </div>
    )
}

export default MobileCalendar

